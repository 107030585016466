import { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ScrollToTop from "react-scroll-to-top";
import "bootstrap-icons/font/bootstrap-icons.css";
import { Link } from 'react-router-dom';
import BASE_URLS from '../baseUrl';
import Logo from '../Images/rathin-white-logo.png'

function Footer() {  
  const currentYear = new Date().getFullYear();
  const webUrl = BASE_URLS.webUrl;
  const mainApiUrl = BASE_URLS.main;
  const [addr, setAddr] = useState('');

  useEffect(() => {
    const getCurrAddr = async () => {
      const resAdd = await fetch(`${mainApiUrl}/get_logos.php`);
      const getdataAdd = await resAdd.json();
      setAddr(getdataAdd);
    }
    getCurrAddr();
  }, [mainApiUrl]);

  return (
    <footer className="footer-style-1">
        <Container>
            <Row className="gy-3 copyright">
                <Col md={6}>
                    <ul className="list-inline mb-0">
                        <li className="list-inline-item"> <Link to={`${webUrl}about-us`}>About Us</Link></li>
                        <li className="list-inline-item"> <Link to={`${webUrl}contact-us`}>Contact Us</Link></li>
                        <li className="list-inline-item"> <Link to={`${webUrl}privacy-policy`}>Privacy Policy</Link></li>
                        <li className="list-inline-item"> <Link to={`${webUrl}cookie-policy`}>Cookie Policy</Link></li>
                        <li className="list-inline-item"> <Link to={`${webUrl}terms-of-use`}>Terms of Use</Link></li>
                    </ul>
                </Col>
                <Col md={4}>
                    <p className="mb-0">©2024 Copyright Trypexpert L.L.C-FZ. All Rights Reserved</p>
                </Col>
                <Col md={2} className="footer-links text-right">
                    <div className="social-links d-flex justify-content-center">
                        <Link to={addr.twitter_link} target="_blank" rel="noopener noreferrer">
                            <button className="social-media" aria-label="Rathin Tours Instagram">
                                <i className="bi bi-twitter"></i>
                            </button>
                        </Link>
                        <Link to={addr.fb_link} target="_blank" rel="noopener noreferrer">
                            <button className="social-media" aria-label="Rathin Tours Facebook">
                                <i className="bi bi-facebook"></i>
                            </button>
                        </Link>
                        <Link to={addr.insta_link} target="_blank" rel="noopener noreferrer">
                            <button className="social-media" aria-label="Rathin Tours Twitter">
                                <i className="bi bi-instagram"></i>
                            </button>
                        </Link>
                        <Link to={addr.li_link} target="_blank" rel="noopener noreferrer">
                            <button className="social-media" aria-label="Rathin Tours Linkedin">
                                <i className="bi bi-linkedin"></i>
                            </button>
                        </Link>
                    </div>
                </Col>
            </Row>
        </Container>
        {/* <div className="footer-1">
            <Container>
                <Row className="gy-3">
                    <Col lg={3} md={6} sm={12} className="footer-links d-flex">
                        <i className="bi bi-geo-alt icon"></i>
                        <div>
                            <h4>Address</h4>
                            <address><b>Trypexpert L.L.C-FZ</b> <br />Office 1, Bahzad Bldg, <br />Soug Al Kabeer, Bur Dubai, Dubai, <br />UAE. PO Box: 46008</address>
                        </div>
                    </Col>
                    <Col lg={3} md={6} sm={12} className="footer-links d-flex">
                        <i className="bi bi-telephone icon"></i>
                        <div>
                            <h4>Enquiry</h4>
                            <p><strong>Phone:</strong> <Link to="tel:+971 56 537 4934">+971 56 537 4934</Link></p>
                
                            <p><strong>Email:</strong> <Link to="mailto:contact@trypexpert.com">contact@trypexpert.com</Link></p>
                        </div>
                    </Col>
                    <Col lg={2} md={4} sm={6}  className="footer-links d-flex">
                        <i className="bi bi-link icon"></i>
                        <div>
                            <h4>Site Links</h4>
                            <ul>
                                <li>
                                    <i className="bi bi-chevron-right icon1 mr-1"></i>
                                    <Link to={`${webUrl}about-us`} > About Us</Link>
                                </li>
                                <li>
                                    <i className="bi bi-chevron-right icon1 mr-1"></i>
                                    <Link to={`${webUrl}contact-us`}> Contact Us</Link>
                                </li>
                                <li>
                                    <i className="bi bi-chevron-right icon1 mr-1"></i>
                                    <Link to={`${webUrl}help-center`}> Help Center</Link>
                                </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={2} md={4} sm={6} className="footer-links d-flex">
                        <i className="bi bi-link icon"></i>
                        <div>
                            <h4>Useful Links</h4>
                            <ul>
                            <li>
                                <i className="bi bi-chevron-right icon1 mr-1"></i>
                                <Link to={`${webUrl}privacy-policy`}> Privacy Policy</Link>
                            </li>
                            <li>
                                <i className="bi bi-chevron-right icon1 mr-1"></i>
                                <Link to={`${webUrl}cookie-policy`}>Cookie Policy</Link>
                            </li>
                            <li>
                                <i className="bi bi-chevron-right icon1 mr-1"></i>
                                <Link to={`${webUrl}terms-of-use`}>Terms of Use</Link>
                            </li>
                            </ul>
                        </div>
                    </Col>
                    <Col lg={2} md={4} sm={12} className="footer-links text-md-center text-left">
                        <h4>Follow Us</h4>
                        <div className="social-links d-flex justify-content-center">
                            <Link to="#" target="_blank" rel="noopener noreferrer">
                                <button className="social-media" aria-label="Rathin Tours Instagram">
                                    <i className="bi bi-twitter"></i>
                                </button>
                            </Link>
                            <Link to="#" target="_blank" rel="noopener noreferrer">
                                <button className="social-media" aria-label="Rathin Tours Facebook">
                                    <i className="bi bi-facebook"></i>
                                </button>
                            </Link>
                            <Link to="#" target="_blank" rel="noopener noreferrer">
                                <button className="social-media" aria-label="Rathin Tours Twitter">
                                    <i className="bi bi-instagram"></i>
                                </button>
                            </Link>
                            <Link to="#" target="_blank" rel="noopener noreferrer">
                                <button className="social-media" aria-label="Rathin Tours Linkedin">
                                    <i className="bi bi-linkedin"></i>
                                </button>
                            </Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <div className="footer-2 copyright">
            <Container>
            <Row>
                <Col  md={12} >
                    <p className="mb-0">&copy;{currentYear} Copyright <b>Trypexpert L.L.C-FZ</b>. All Rights Reserved</p>
                </Col>
            </Row>
            </Container>
        </div> */}
    </footer>
  )
}
export default Footer;